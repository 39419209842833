body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: 1.25rem;
}

* {
	font-size: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input:not([type]), input[type="text"], input[type="password"] {
  padding: 1rem;
  width: 100%;
  max-width: 80rem;
  box-sizing: border-box;
}

input[type="radio"] {
	margin-right: 1rem;
}

textarea {
	padding: 1rem;
	resize: vertical;
	display: block;
	width: 100%;
  max-width: 80rem;
  min-height: 7rem;
  font-family: inherit;
  box-sizing: border-box;
}

button {
  padding: 1rem 2rem;
  font-weight: bold;
}

button + button {
	margin-left: 1rem;
}

select {
	padding: 1rem;
}

.popup-content {
	/* No idea why the react popup library doesn't come with this */
  width: 80% !important;
  max-height: 80%;
  overflow-y: auto;
}
