.root {
  font-size: 1.3rem;
}

.root h1 {
	padding: 1rem 2rem;	
	margin: 0;
}

.contents {
	display: flex;
	min-height: 30rem;
}

.contents pre {
  white-space: pre-wrap;
}

.left {
	flex: 0 0 auto;
	border-right: solid 1px #ddd;
	padding: 2rem;
}

.right {
	flex: 1 1 0;
	padding: 2rem;
}
