.root {
	height: 100%;
	display: flex;
	flex-direction: column;
}

.header {
	flex: 0 0 auto;

	padding: 1rem;
	background-color: #eee;

  text-align: center;
}

.header h2 {
  font-family: "Kode Mono", monospace;
  font-size: 4rem;
  color: #AF5A6B;
  font-weight: 900;
  margin: 0;
  display: inline;
}

.header p {
	margin: 1.5rem 0 0 0;
	float: right;
}

.content {
	flex: 1 1 0;
	overflow-y: auto;
	padding: 3rem;
}

.content ul {
	padding: 0;
}

.content li {
	list-style-type: none;
	border: solid 1px #aaa;
	border-radius: 1rem;
	padding: 1rem;
	margin: 1rem 0;
}

.login {
	padding: 2rem;
}

.login label {
	display: block;
}

.login span {
	display: block;
	margin: 0 0 1rem 0;
}

.login input {
	margin: 0 0 2rem 0;
}

.createApiConfig {
	margin: 2rem;
}

.editApiConfig {
	margin: 2rem;
}

.editApiConfig > input, .editApiConfig > textarea, .editApiConfig > p {
	margin: 0 0 1rem 0;
}

.editApiConfig .addFunctionButton {
	margin-bottom: 1rem;
}

.editDeclaredFunctionConfig {
	border: solid 1px #aaa;
	border-radius: 1rem;
	padding: 1rem 1rem 0 1rem;
	margin: 0 0 1rem 0;
}

.editDeclaredFunctionConfig p {
	margin: 0 0 1rem 0;
}

.editDeclaredFunctionConfig label {
  display: block;
  margin: 0 0 1rem 0;
}

.editDeclaredFunctionConfig button {
  margin: 0 0 1rem 0;
}

.editDeclaredFunctionConfig .delete {
	color: red;
	font-weight: bold;
	cursor: pointer;
}

.editDeclaredFunctionConfig input {
	
}

.editParameterConfig {
  border: solid 1px #aaa;
  border-radius: 1rem;
  padding: 1rem 1rem 0 1rem;
  margin: 0 0 1rem 0;
}

.expander {
	cursor: pointer;
	margin-bottom: 1rem;
  font-size: 2.5rem;
  line-height: 2.5rem;
}

.expandableTitle {
	display: flex;
  gap: 1rem;
  align-items: center;
}
