.root {
	border-radius: 1rem;
	overflow: hidden;
	min-height: 10rem;
	padding: 1rem;
	border: solid 1px #ddd;
}

.user {
}

.message {
	display: flex;
	align-items: start;
}

.message .personIcon {
	font-size: 4rem;
	margin: 1rem;
}

.message .name {
	font-weight: bold;
}

.message p {
	white-space: pre-wrap;
	margin: 0.4rem 0;
}

hr {
	height: 1px;
	background-color: #ddd;
	border: none;
}

.writeMessage {
  display: flex;
}

.writeMessage input {
  flex: 1 1 0;
}

.writeMessage button {
  flex: 0 0 auto;
}
