.root {
	width: 90%;
	margin: 0 auto;
	max-width: 80rem;
	line-height: 1.5;
	margin-top: 10%;
	transition: margin-top 500ms;
}

.apiLoaded {
	margin-top: 5rem;
}

.root h1 {
  text-align: center;

  font-family: "Kode Mono", monospace;
  font-size: 4rem;
  color: #AF5A6B;
  font-weight: 900;
}

.contactUsPopup {
	/*width: 80rem;*/
}

:global(.contactUsPopup-content) {
	max-width: 40rem;
	height: 100%;
}

:global(.contactUsPopup-content) iframe {
	height: 100%;
  display: block;
}

.examples {
	font-size: 0.8rem;
	overflow-wrap: anywhere;
}

.examples p {
	margin-left: 0.5rem;
	margin-bottom: 0.25rem;
}

.examples a {
  border: solid 1px #ddd;
  padding: 0.75rem 1rem;
  margin: 0 0.5rem 0.5rem 0;
  max-width: 20rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 3.25rem;
  border-radius: 2rem;
  color: black;  
}

.examples a:hover {
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);	
}

.chat {
	margin: 1rem 0 0 0;
}

.loading {
	font-weight: bold;
	margin: 2rem 0 0 4rem;
}

.loading:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: "\2026";
  /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 30px;
  }
}

.chatHeader p {
  white-space: pre-wrap;
  margin-top: 0;
}

.chatHeader textarea {
	width: 100%;
	min-height: 10rem;
	resize: vertical;
}

.contactUsLink {
	font-size: 0.8rem;
	color: #AF5A6B;
	font-weight: bold;
	margin: 2rem 0 2rem 2rem;
	display: block;
}
